// Override core variables here (defined in ~tmr/tmr/variables.scss).
// This file is imported automatically by webpack. You do not need to import it manually.

// Example:
// $font-family: 'Comic Sans MS';

tmr-resource.alarm tmr-resource-td{
  background-color: #c00 !important;
  color: #fff !important;
}

tmr-resource.alert tmr-resource-td{
  background-color: orange !important;
}

.md-button.md-primary.md-raised:not([disabled]){
  color: white;
  background-color: #c03a81;
}

.md-button.md-primary.md-raised:not([disabled]).md-focused{
  color: white;
  background-color: #c03a81;
}

md-switch.md-checked:not([disabled]).md-primary .md-bar{
  background-color: #f256a8;
}

md-switch.md-checked:not([disabled]).md-primary .md-thumb{
  background-color: #c03a81;
}

.md-select-value > span:not(.md-select-icon) .md-text, tmr-workstation-select md-input-container md-select .md-select-value.md-select-placeholder > *{
  color: #f256a8 !important;
}

.o-tv-screen-1::before{
  color: #c03a81 ;
}

.md-select-value .md-select-icon:after{
  color: #c03a81 ;
}

tmr-main-menu md-list-item.main-menu-level-2.active .menu-item-border-left{
  background-color: #c03a81 !important;
}

md-progress-circular path{
  stroke: #c03a81 !important;
}


tmr-main-menu md-list-item.active .menu-item-border-left{
  background-color: #c03a81 !important;

}

md-input-container:not(.md-input-invalid).md-input-focused .md-input{
  border-color: #c03a81 !important;
}

md-select:not([disabled]):focus .md-select-value{
  border-bottom-color: #c03a81 !important;
}

md-chips .md-chips.md-focused{
  box-shadow: 0 2px rgb(192,58,129);
}
md-chips md-chip.md-focused{
  background: #c03a81 !important;
}

.md-button.md-primary.md-raised:not([disabled]):hover{
  background: #c03a81 !important;
}

.md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator{
  border: 1px rgb(192,58,129);
}

.md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator{
  background-color: rgb(192,58,129);
  color: white;
}

md-dialog.mdp-timepicker {
  .mdp-timepicker-time {
    background-color: #c03a81 !important;
    color: white;
  }
  .mdp-clock {
    border: 2px solid black;
    background-color: white;
  }

  .mdp-clock-container .md-button {
    font-weight: bold;
  }

  md-dialog-actions .md-button.md-primary {
    color: white;
    background-color: #c03a81 !important;
  }
}

.md-input-focused md-icon, .md-datepicker-open .md-datepicker-calendar-icon {
  color:  #c03a81 !important;
}

.mdp-clock-container md-toolbar:not(.md-menu-toolbar){
  background-color: black;
}

.o-add-circle-1::before, tmr-logo-sidebar .sidebar-logo-icon:hover .tmr-icon{
  color: white;
}








